/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const CaPriceVariant = (data) => {
    const variants = JSON.parse(data?.variants?.[0]?.value);
    // Filter the campaignResponses based on the campaignName
    const filteredCampaign = variants?.campaign?.campaignResponses?.filter((response) => response?.campaignName?.includes('CA Transparency Delivery Fee'));
    // Extract the testType from the payload of the first filtered campaign
    const testType = filteredCampaign?.[0]?.payload?.testType || [];
    // Return JSX with true or false
    return !!testType.includes('ca_delivery');
};

export default CaPriceVariant;
